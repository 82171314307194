import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Container, Grid, Typography, useTheme, Button, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/cargo-layout/breadcrumb/Breadcrumb';
import ControlPanel from '../../../components/controlPanels/withTimeframe/ControlPanel';
import HeatMap from '../../../components/headMap/HeatMap';
import Spinner from '../../spinner/Spinner';
import { getAllCargo, getListLoad, updateKpi } from '../../../api/cargo';
import { getCompany } from '../../../api/company';
import useAuth from '../../../hooks/useAuth';
import { modules } from '../../../constants/permissions';
import { getOrderList, getKPIOrders } from '../../../api/order';
import LoadChart from './LoadChart';
import LoadsPerTime from './LoadsPerTime';
import ScreenSizeContext from '../../../contexts/ScreenSizeContext';
import { CheckPermissions } from '../../../utils/relatedToPermissions';
import { sxTableButtons } from '../../../constants/styleTableButton';
import KPIcreated from '../../../components/controlPanels/kpiCreated/KPIcreated';
import { getCreatedKPI } from '../../../api/kpi';

const LoadsPanelControl = () => {
  const auth = useAuth();
  const p = auth?.allPermissions;
  const navigate = useNavigate();
  const [t] = useTranslation();
  const {
    mxTableButtons,
    myTableButtons,
    widthScreen,
    mtTypographyTableButtons,
    typographyVariantButton,
  } = useContext(ScreenSizeContext);
  const [firstSession, setFirstSession] = useState(null);
  const [loads, setLoads] = useState(null);
  const [orders, setOrders] = useState(null);
  const [dataCreatedKpi, setDataCreatedKpi] = useState([]);
  const [loadsStatus, setLoadsStatus] = useState(null);
  console.log(loadsStatus);
  const [enableView, setEnableView] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();

  const location = useLocation();

  function getModule() {
    return location.pathname.split('/')[1];
  }

  const isCarga = getModule() !== 'torre-de-control';
  const currentModule = isCarga ? 'carga' : 'torre-de-control';

  const BCrumb = [
    currentModule === 'carga'
      ? {
          to: '/carga/inicio',
          title: t('Loads.Loads'),
        }
      : {
          to: '/torre-de-control/inicio',
          title: t('Torre de Control'),
        },
    {
      title: t('Control-panel'),
    },
  ];

  const fetchData = async () => {
    try {
      const [respFirstSession, respLoad, respTypeLoads, respOrders, respKpiCreated] =
        await Promise.all([
          getCompany(auth?.authenticatedData?.idEmpresa),
          getListLoad(),
          getOrderList(),
          getKPIOrders(),
          getCreatedKPI(),
        ]);
      const toStatusData = Object.keys(respTypeLoads.data).map((key) => {
        return {
          estado: key,
          cantidad: respTypeLoads.data[key].length,
          nroCarga: respTypeLoads.data[key],
        };
      });
      setLoadsStatus(toStatusData);
      setFirstSession(respFirstSession.data.primeraSesion);
      setLoads(respLoad.data);
      setOrders(respOrders.data);
      setDataCreatedKpi(respKpiCreated.data.kpi);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setEnableView(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleNewKPIButton = () => {
    navigate('/carga/kpi');
  };

  const syncUpKpi = async () => {
    try {
      setEnableView(false);
      await updateKpi();
    } catch (e) {
      console.log('error', e);
    } finally {
      fetchData();
    }
  };

  if (!enableView) {
    return (
      <Container maxWidth={false}>
        <Spinner />
      </Container>
    );
  }

  return (
    <PageContainer title={t('Control-panel')} description={t('Loads.Table.BreadCrumbTitle')}>
      <Breadcrumb items={BCrumb} />
      <Grid container p={{ sx: 0, sm: 0.5, md: 1 }}>
        <Grid
          item
          container
          xs={12}
          sm={widthScreen > 717 ? 7 : 12}
          md={6}
          sx={{
            justifyContent: widthScreen > 450 ? 'start' : 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{ ...sxTableButtons, mx: mxTableButtons, my: myTableButtons }}
            variant="contained"
            color="primary"
            size="small"
            endIcon={
              <RefreshIcon
                style={{ fontSize: '24px' }}
                sx={{ mr: widthScreen > 450 ? 0.5 : 0.4, ml: widthScreen > 420 ? 0 : -0.6 }}
              />
            }
            onClick={syncUpKpi}
          >
            <Typography
              variant={typographyVariantButton}
              sx={{ ml: widthScreen > 420 ? 0 : -0.5, mt: mtTypographyTableButtons }}
            >
              {t('Update Charts')}
            </Typography>
          </Button>
          {/* {!error && <Button
                disabled={!CheckPermissions(auth, [p?.ROOT, p?.CRE_EMP, p?.CRE_PED])}
                onClick={handleNewKPIButton}
                variant = "contained"
                color="primary"
                size = 'small'
                sx={{...sxTableButtons, mx:mxTableButtons, my:myTableButtons}}
                selected = {false}
                startIcon = { <AddBoxIcon style = {{ fontSize: '24px' }} /> }
              >              
                <Typography variant={typographyVariantButton} sx={{ml:widthScreen>420?0: -0.5 ,mt:mtTypographyTableButtons}}>
                {t('New-KPI')}
                </Typography>
              </Button>} */}
        </Grid>
      </Grid>

      {error ? (
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '50vh',
          }}
        >
          <WifiOffIcon
            fontSize="large"
            sx={{ width: '10rem', height: '10rem', color: theme.palette.primary.main }}
          />
          <Typography variant="h3">{t('server error')}</Typography>
          <Typography variant="h4" textAlign="center" py={0.5}>
            {t('mjs-server error')}
          </Typography>
          <Typography variant="h4" textAlign="center" py={0.5}>
            {t('mjs2-server error')}
          </Typography>
        </Grid>
      ) : (
        <Grid container spacing={0}>
          {/* {dataCreatedKpi.length > 0 && 
            dataCreatedKpi.map((kpi, i)=>{
              if(kpi.data.length > 0) {
                return (
                  <Grid item md={6} sm={12} xs={12} key={`kpi-propio-${kpi.id}`}>
                    <KPIcreated data={kpi} firstSession={firstSession}/>
                  </Grid>
                  )} 
              return <></>
            })
          } */}
          <Grid item xs={12} sm={12} md={12}>
            <Divider sx={{ height: 10, backgroundColor: 'orange', my: 4, mx: 2 }} />
          </Grid>
          {loads.length > 0 && (
            <Grid item md={6} sm={12} xs={12}>
              <ControlPanel
                title={t('Loads-and-amounts-by-periods')}
                data={loads}
                firstSession={firstSession}
                module={modules.load}
              />
            </Grid>
          )}
          {orders.length > 0 && (
            <Grid item md={6} sm={12} xs={12}>
              <LoadsPerTime
                title={t('finishedOrders')}
                data={orders}
                firstSession={firstSession}
                module={modules.load}
              />
            </Grid>
          )}
          {loads.length > 0 && (
            <Grid item md={6} sm={12} xs={12}>
              <HeatMap data={loads} module={modules.load} />
            </Grid>
          )}
          <Grid item md={12} sm={12} xs={12}>
            <LoadChart
              title={t('Total-loads-by-state')}
              data={loadsStatus}
              xDataKey="estado"
              barDataKey="cantidad"
              strToolstip={t('Plans.Loads')}
              borderBarColor="#B62900"
              barColor="#E77E00"
            />
          </Grid>
        </Grid>
      )}
    </PageContainer>
  );
};

export default LoadsPanelControl;
