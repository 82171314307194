import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Rating,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/fleet-layout/breadcrumb/Breadcrumb';
import { getOtDetails } from '../../../api/viaje';
import CargoHead from './tables/CargoHead';
import TableCard from '../../../components/table-card/TableCard';
import { createScore, getLoadScoreDetails } from '../../../api/score';
import TablePedidos from './tables/TablePedidos';
import TableScoreLoad from './tables/TableScoreLoad';
import useAuth from '../../../hooks/useAuth';
import { FRONT_URL } from '../../../config';

const Score = () => {
  const [t] = useTranslation();
  const { authenticatedData } = useAuth();
  const { id: orderId } = useParams();
  const [travel, setTravel] = useState(null);
  const [score, setScore] = useState([]);
  const [puntaje, setPuntaje] = useState(0);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('nroCarga');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const URL = `${FRONT_URL}/calificacion/cliente`;

  const location = useLocation();
  const { cargaId } = location.state || {};

  function getModule() {
    return location.pathname.split('/')[1];
  }

  const isCarga = getModule() === 'carga';
  const currentModule = isCarga ? 'carga' : 'flota';

  const BCrumb = [
    {
      to: '/',
      title: t('Fleet.My-Travels.Details.Home'),
    },
    {
      to: '/flota/viajes',
      title: t('Fleet.My-Travels.Details.List'),
    },
    {
      title: 'Calificaciones',
    },
  ];
  const handleRequestSort = (event, property) => {
    const isASC = orderBy === property && order === 'asc';
    setOrder(isASC ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTravel = async () => {
    const { data } = await getOtDetails(orderId);
    setTravel(data);
  };

  const getScores = async (id) => {
    const { data } = await getLoadScoreDetails(cargaId);
    setScore(data);
  };

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const scoring = async (value, carga) => {
    try {
      const { data } = await createScore({
        descripcion: '',
        idCarga: carga.id,
        idEmpresaOrigen: carga.idEmpresaCreadora,
        idEmpresaDestino: carga.idEmpresaTransportista || carga.idEmpresaTransportadora,
        idViaje: carga.idViaje,
        puntos: value,
      });
      setPuntaje(data.puntos);
      showMessage('Calificado correctamente', 'success');
      // window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getTravel();
  }, []);

  const handleScore = () => {
    let calificaciones = [];

    travel?.califDadores?.forEach((element) => {
      calificaciones = [...calificaciones, element];
    });

    const puntuacion = travel?.califDadores?.find(
      (dador) => dador.empresaOrigen?.idFiscal === authenticatedData.idFiscalEmpresa,
    ) || { puntos: 0 };

    setPuntaje(puntuacion.puntos);
  };

  useEffect(() => {
    if (travel) {
      handleScore();
      getScores();
    }
  }, [travel]);

  return (
    <PageContainer title="Calificación" description="Calificación de cargas">
      <Breadcrumb items={BCrumb} />
      <Grid sx={{ marginBottom: '5rem', marginTop: '4rem' }}>
        <TableCard
          title={<Typography variant="h1">{t('ModalScore.ModalTitle')}</Typography>}
          custompadding="0px"
          customMargin="0px"
        >
          <Box sx={{ overflow: 'auto' }}>
            <TableContainer>
              <Table
                aria-label="Calificación"
                sx={{
                  whiteSpace: {
                    xs: 'nowrap',
                    sm: 'unset',
                  },
                }}
              >
                <CargoHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  module={currentModule}
                />
                {/* {score &&
                  score?.calificacionesDelDador?.map((item) => { */}
                {/* return ( */}
                <TableRow hover>
                  <TableCell sx={{ borderBottom: '0' }}>
                    <Typography variant="h5" fontWeight="600">
                      {travel?.nroViaje || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: '0' }}>
                    <Typography color="textSecondary" variant="h5" fontWeight="600">
                      {travel?.descripcion || '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: '0', zIndex: 1000 }}>
                    <Rating
                      name="simple-controlled"
                      value={puntaje}
                      onChange={(event, newValue) => {
                        scoring(newValue, score);
                      }}
                      readOnly={puntaje !== 0}
                    />
                  </TableCell>
                </TableRow>
                {/* );
                    // })} */}
              </Table>
            </TableContainer>
          </Box>
        </TableCard>
      </Grid>
      <Grid>
        <TableScoreLoad rows={score} />
      </Grid>
      <Grid sx={{ mt: '2rem' }}>
        <TablePedidos rows={score} />
      </Grid>
      <Grid container spacing={1}>
        <Grid container sx={{ mt: 2, mb: 2 }} justifyContent="space-between">
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Button
              size="small"
              onClick={() => navigate(-1)}
              sx={{
                width: '200px',
                mt: 2,
                mb: 2,
              }}
              variant="contained"
              color="error"
            >
              <Typography variant="h3">{t('Button.Back')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Score;
