// const BASE_URL
export const BASE_URL = 'https://follow.followlsn.com';
export const FRONT_URL = 'https://demo.followlsn.com';
// export const BASE_URL = 'http://localhost:8080';
export const KPI_URL = 'https://kpi-demo.followlsn.com';
export const BAHIA_URL = 'https://schedule-demo.followlsn.com';
// export const BASE_URL = 'http://localhost:8080'; 
// export const FRONT_URL = 'http://localhost:3000';

const API_PATH = `${BASE_URL}/api`;
const KPIAPI_PATH = `${KPI_URL}/api`;
const BAHIA_PATH = `${BAHIA_URL}/api`;

// ** AUTH ** //
// export const AUTH_PATH = 'http://localhost:8080/api/auth';
export const AUTH_PATH = `${API_PATH}/auth`;

// ** ENUMERACIONES ** //
export const ENUMS_PATH = `${API_PATH}/enumeraciones`;

// ** FLEET ORDER ** //
export const FLEET_PATH = `${API_PATH}/flota`;

// ** LOAD TYPE ** //
export const LOAD_TYPE_PATH = `${API_PATH}/tipo-carga`;

// ** MEASUREMENT UNIT ** //
export const MEASUREMENT_UNIT_PATH = `${API_PATH}/unidad-medida`;

// ** VEHICLE TYPE ** //
export const VEHICLE_TYPE_PATH = `${API_PATH}/tipo-vehiculo`;

// ** TRUCK TYPE ** //
export const TRUCK_TYPE_PATH = `${API_PATH}/tipo-camion`;

// ** TRAILER TYPE ** //
export const TRAILER_TYPE_PATH = `${API_PATH}/tipo-remolque`;

// ** BITREN TYPE ** //
export const BITREN_TYPE_PATH = `${API_PATH}/tipo-bitren`;

// ** ULTIMA MILLA ** //
export const LAST_MILE_TYPE = `${API_PATH}/tipo-ultima-milla`;

// ** FEATURES ** //
export const FEATURES_PATH = `${API_PATH}/caracteristica`;

// ** ACCESSORIES ** //
export const ACCESSORIES_PATH = `${API_PATH}/accesorio`;

// ** PACKAGING TYPE ** //
export const PACKAGING_TYPE_PATH = `${API_PATH}/tipo-embalaje`;

// ** PALLET TYPE ** //
export const PALLET_TYPE = `${API_PATH}/tipo-pallet`;

// ** CURRENCY ** //
export const CURRENCY_PATH = `${API_PATH}/moneda`;

// ** PAYMENT CONDITIONS ** //
export const PAYMENT_CONDITIONS_PATH = `${API_PATH}/condicion-pago`;

// ** CUSTOMER ** //
export const CUSTOMER_PATH = `${API_PATH}/cliente`;

// ** VEHICLE ** //
export const VEHICLE_PATH = `${API_PATH}/vehiculo`;

// ** MAINTENANCE ** //
export const MAINTENANCE_PATH = `${API_PATH}/orden-mantenimiento`;

// ** ORDER-BAJA ** //

export const ORDER_BAJA_PATH = `${API_PATH}/orden-baja`;

// ** CARGO ** //
export const CARGO_PATH = `${API_PATH}/carga`;

export const KPI_CARGO_PATH = `${KPIAPI_PATH}/carga`;

// ** PEDIDOS ** //
export const KPI_PEDIDO_PATH = `${KPIAPI_PATH}/pedido`;

// ** GASTOS ** //
export const EXPENSE_PATH = `${API_PATH}/gasto`;

export const KPI_EXPENSE_PATH = `${KPIAPI_PATH}/gasto`;

// ** PLAN ** //
export const PLAN_PATH = `${API_PATH}/plan`;

// ** SUSCRIPCION ** //
export const SUSCRIPCION_PATH = `${API_PATH}/suscripcion`;

// ** PROMOTIONS **//
export const PROMOTIONS_PATH = `${API_PATH}/promocion`;

// ** CHECKLIST ** //
export const CHECKLIST_PATH = `${API_PATH}/checklist`;
export const ITEM_CHECKLIST_PATH = `${API_PATH}/item`;

// ** TRAILER ** //
export const TRAILER_PATH = `${API_PATH}/remolque`;

// ** DRIVER ** //
export const DRIVER_PATH = `${API_PATH}/chofer`;

// ** OTHER ASSETS ** //
export const OTHER_ASSETS_PATH = `${API_PATH}/activo`;

// ** LOCATION ** //
export const LOCATION_PATH = `${API_PATH}/ubicacion`;

// ** LOCALITY ** //
export const LOCALITY_PATH = `${API_PATH}/localidad`;

// ** PROVINCE ** //
export const PROVINCE_PATH = `${API_PATH}/provincia`;

// ** COUNTRY ** //
export const COUNTRY_PATH = `${API_PATH}/pais`;

// ** MODEL ** //
export const MODEL_PATH = `${API_PATH}/modelo`;

// ** BRAND ** //
export const BRAND_PATH = `${API_PATH}/marca`;

// ** ORDER ** //
export const ORDER_PATH = `${API_PATH}/pedido`;

// ** NOTIFICATION ** //
export const NOTIFICATION_PATH = `${API_PATH}/notificacion`;

// ** FAVORITE ** //
export const FAVORITE_PATH = `${API_PATH}/favoritos`;

// ** MILESTONE ** //
export const MILESTONE_PATH = `${API_PATH}/hito`;

// ** MILESTONE ** //
export const DOCUMENTS_PATH = `${BASE_URL}/api/documentacion/listado/empresa`;

// ** COMPANY ** //
export const COMPANY_PATH = `${API_PATH}/empresa`;

// ** MARKET-PLACE ** //
export const MARKET_PLACE_PATH = `${API_PATH}/market-place`;

// ** PERMISSIONS ** //
export const PERMISSIONS_PATH = `${API_PATH}/permiso`;
export const PERMISSIONS_CATEGORIES_PATH = `${API_PATH}/categoriaPermiso`;
export const PERMISSIONS_SUBCATEGORIES_PATH = `${API_PATH}/subcategoria-permiso`;

// ** ROL ** //
export const ROL_PATH = `${API_PATH}/rol`;

// ** PREGUNTA MARKET-PLACE ** //
export const PREGUNTA_MARKET_PLACE_PATH = `${API_PATH}/pregunta-market`;

// ** CONTRAOFERTA ** //
export const CONTRAOFERTA_PATH = `${API_PATH}/contraoferta`;

// ** ALERTAS ** //
export const ALERTAS_PATH = `${API_PATH}/alerta`;

// ** VIAJES ** //
export const VIAJE_PATH = `${API_PATH}/viaje`;

export const KPI_VIAJE_PATH = `${KPIAPI_PATH}/viaje`;

// ** NUEVOS ** //

// ** INFO ** //
export const INFO_PATH = `${API_PATH}/info`;

// ** USERS ** //
export const USERS_PATH = `${API_PATH}/usuario`;

// ** TEMPLATES ** //
export const TEMPLATES_PATH = `${API_PATH}/plantillas`;

// ** ORDERS ** //
export const ORDERS_PATH = `${API_PATH}/ordenes`;

// ** PROCESSES ** //
export const PROCESSES_PATH = `${API_PATH}/procesos`;

// ** PROCEDURES ** //
export const PROCEDURES_PATH = `${API_PATH}/procedimiento`;

// ** STORAGE ** //
export const WORKFLOW_STORAGE_PATH = `${API_PATH}/almacenamiento`;

// ** DOCUMENT PERMISSIONS ** //
export const WORKFLOW_DOCUMENT_PERMISSION_PATH = `${API_PATH}/permisoDocumento`;

// ** WORKFLOW ORDER ** //
export const WORKFLOW_ORDER_PATH = `${API_PATH}/orden`;

// ** WORKFLOW TEMPLATE ** //
export const WORKFLOW_TEMPLATE_PATH = `${API_PATH}/plantilla`;

// ** WORKFLOW FOLDER ** //
export const WORKFLOW_FOLDER_PATH = `${API_PATH}/carpeta`;

// ** WORKFLOW FORM ** //
export const WORKFLOW_FORM_PATH = `${API_PATH}/formulario`;

// ** WORKFLOW DOCUMENT ** //
export const WORKFLOW_DOCUMENT_PATH = `${API_PATH}/documento`;

// ** WORKFLOW TAG ** //
export const WORKFLOW_TAG_PATH = `${API_PATH}/etiqueta`;

// ** WORKFLOW PROCESS ** //
export const WORKFLOW_PROCESS_PATH = `${API_PATH}/proceso`;

export const WORKFLOW_DOCUMENTAL_PROCESS_PATH = `${API_PATH}/proceso-documental`;

// ** WORKFLOW PROCESS TYPE ** //
export const WORKFLOW_PROCESS_TYPE_PATH = `${API_PATH}/tipoProceso`;

// ** WORKFLOW USER ** //
export const WORKFLOW_USERS_PATH = `${API_PATH}/usuario`;

// ** WORKFLOW PROCEDURE ** //
export const WORKFLOW_PROCEDURE_PATH = `${API_PATH}/arbolprocedimiento`;

// ** WORKFLOW DEPARTMENT ** //
export const WORKFLOW_DEPARTMENT_PATH = `${API_PATH}/departamento`;

export const LIST_DOCUMENTS_PATH = `${API_PATH}/documentacion`;

export const DOCUMENT_TYPES_PATH = `${API_PATH}/tipo-documentacion`;

// ** ACTIVOS ** //
export const ACTIVOS_PATH = `${API_PATH}/activo`;

export const KPI_ACTIVOS_PATH = `${KPIAPI_PATH}/activo`;

// ** CALIFICACION ** //
export const CALIFICACION_PATH = `${API_PATH}/calificacion`;

// ** FLOTAS TERCERIZADAS ** //
export const FLEET_OUTSOURCED_PATH = `${API_PATH}/flota-tercerizada`;

// ** BAJA-SUSCRIPCION ** //
export const BAJA_SUSCRIPCION_PATH = `${API_PATH}/baja-suscripcion`;
// ** CONSULTAS ** //
export const CONSULTATION = `${API_PATH}/consulta`;

// ** OFERTA ** //
export const OFFER = `${API_PATH}/oferta`;

// ** NCM ** //
export const NCM_PATH = `${API_PATH}/ncm`;

// ** PRODUCTO ** //
export const PRODUCT_PATH = `${API_PATH}/producto`;

// ** CODE ** //
export const CODE_PATH = `${API_PATH}/codigodescuento`;

// ** TARIFARIO ** //
export const FEE_PATH = `${API_PATH}/tarifario`;

// ** PROCEDIMIENTO** //
export const PROCEDURE = `${API_PATH}/procedimiento`;

// ** WORKFLOW WorQuestionTypes** //
export const QUESTIONTYPES = `${API_PATH}/tipo-pregunta`;

// ** TIPO CONTENEDOR ** //
export const CONTAINER_TYPE_PATH = `${API_PATH}/tipo-contenedor`;

// ** BAHAIA ** //
export const BAHAIA = `${BAHIA_PATH}/bahia`;

// ** Dock ** //
export const DOCK = `${BAHIA_PATH}/muelle`;

// ** Shift ** //
export const SHIFT = `${BAHIA_PATH}/turno`;

// ** Shift ** //
export const SCHEDULE = `${BAHIA_PATH}/horario`;

// ** KPI ENUMS ** //
export const KPI_ENUM_PATH = `${KPIAPI_PATH}/enumeraciones`;

// ** KPI CUSTOM ** //
export const KPI_CUSTOM_PATH = `${KPIAPI_PATH}/customKPI`;

// ** INCIDENTES ** //
export const INCIDENT_PATH = `${API_PATH}/incidente`;
export const INCIDENTTYPE_PATH = `${API_PATH}/tipo-incidente`;

// ** ABM ** //
export const ABM_PATH = `${BAHIA_PATH}/zona-espera`;

export const NOVELTY_TYPE_PATH = `${API_PATH}/tipo-novedad`;

export const NOVELTY_SUBTYPE_PATH = `${API_PATH}/subtipo-novedad`;

