import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Rating,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import PageContainer from '../../../components/container/PageContainer';
import Breadcrumb from '../../../layouts/fleet-layout/breadcrumb/Breadcrumb';
import { getOtDetails } from '../../../api/viaje';
import CargoHead from './tables/CargoHead';
import TableCard from '../../../components/table-card/TableCard';
import { createScore, getTravelScoreDetails } from '../../../api/score';
import TableScore from './tables/TableScore';
import TablePedidos from './tables/TablePedidos';
import useAuth from '../../../hooks/useAuth';

const LoadScore = () => {
  const [t] = useTranslation();
  const { authenticatedData } = useAuth();
  const [travel, setTravel] = useState(null);
  const [score, setScore] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('nroCarga');
  const [puntaje, setPuntaje] = useState(0);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const { travelId } = location.state || {};

  function getModule() {
    return location.pathname.split('/')[1];
  }

  const { cargaId } = location.state || {};

  const isCarga = getModule() === 'carga';
  const currentModule = isCarga ? 'carga' : 'flota';

  const BCrumb = [
    {
      to: '/',
      title: t('Fleet.My-Travels.Details.Home'),
    },
    {
      to: '/flota/viajes',
      title: t('Fleet.My-Travels.Details.List'),
    },
    {
      title: 'Calificaciones',
    },
  ];

  const handleRequestSort = (event, property) => {
    const isASC = orderBy === property && order === 'asc';
    setOrder(isASC ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTravel = async () => {
    const { data } = await getOtDetails(travelId);
    setTravel(data);
    // setCargas(data.cargas);
  };

  const getScores = async () => {
    try {
      const { data } = await getTravelScoreDetails(travelId);
      setScore(data);
      return data;
    } catch (error) {
      console.error('Error fetching travel score details:', error);
      throw error;
    }
  };

  useEffect(() => {
    getTravel();
  }, []);

  useEffect(() => {
    if (travel) {
      getScores();
    }
  }, [travel]);

  const showMessage = (message, variant) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      autoHideDuration: 4000,
    });
  };

  const scoring = async (value, carga) => {
    try {
      const { data } = await createScore({
        descripcion: '',
        idCarga: carga.id,
        idEmpresaOrigen: carga.idEmpresaTransportista,
        idEmpresaDestino: carga.idEmpresaCreadora,
        idViaje: travel.id,
        puntos: value,
      });
      setScore(data);
      showMessage('Calificado correctamente', 'success');
      getTravel();
    } catch (error) {
      console.log(error.message);
      // showMessage('Error', 'error');
    }
  };

  return (
    <PageContainer title="Calificación" description="Calificación de cargas">
      <Breadcrumb items={BCrumb} />
      <Grid sx={{ marginBottom: '5rem', marginTop: '4rem' }}>
        <TableCard
          title={<Typography variant="h1">{t('ModalScore.ModalTitle')}</Typography>}
          custompadding="0px"
          customMargin="0px"
        >
          <Box sx={{ overflow: 'auto' }}>
            <TableContainer>
              <Table
                aria-label="Calificación"
                sx={{
                  whiteSpace: {
                    xs: 'nowrap',
                    sm: 'unset',
                  },
                }}
              >
                <CargoHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  module={currentModule}
                />
                {score ? (
                  score?.cargas?.map((carga) => (
                    <TableRow hover key={carga.id}>
                      <TableCell sx={{ borderBottom: '0' }}>
                        <Typography variant="h5" fontWeight="600">
                          {carga.nroCarga || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '0' }}>
                        <Typography color="textSecondary" variant="h5" fontWeight="600">
                          {carga.descripcion || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '0' }}>
                        <Typography color="textSecondary" fontWeight="200">
                          {moment(carga.periodoDeDescarga?.fechaFin).format('DD/MM/YYYY HH:mm') ||
                            '---'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: '0' }}>
                        <Typography color="textPrimary" variant="h5" fontWeight="600">
                          {carga.estadoViajeNombre || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" sx={{ borderBottom: '0', zIndex: 1000 }}>
                        {carga.calificacionesDeTransportistas &&
                        carga.calificacionesDeTransportistas.length > 0 ? (
                          carga.calificacionesDeTransportistas.map((calificacion) => (
                            <Rating
                              name='simple-controlled'
                              value={calificacion?.puntos || 0}
                              onChange={(event, newValue) => {
                                scoring(newValue, carga);
                              }}
                              readOnly={!!calificacion?.puntos}
                            />
                          ))
                        ) : (
                          <Rating
                            name="simple-controlled"
                            value={0}
                            onChange={(event, newValue) => {
                              scoring(newValue, carga);
                            }}
                            readOnly={false}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography variant="h6" color="textSecondary">
                        Sin resultados
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </Box>
        </TableCard>
      </Grid>
      <Grid>
        <TableScore rows={score} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} align="left">
          <Button
            size="small"
            // fullWidth={!mdUp}
            onClick={() => navigate(-1)}
            sx={{
              width: '200px',
              mt: 2,
              mb: 2,
            }}
            variant="contained"
            color="error"
          >
            <Typography variant="h3">{t('Button.Back')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LoadScore;
