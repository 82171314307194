import axios from 'axios';
import { ENUMS_PATH } from '../config';

export const getActivities = async (signal = null) => {
  return axios.get(`${ENUMS_PATH}/actividades`, { signal });
};

export const getDocumentTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-documento`);
};

export const getPermissionTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-permiso`);
};

export const getAssetTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-activo`);
};
export const getInactivesTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-baja`);
};

export const getTipoPromocion = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-promocion`);
};

export const getExecutionTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-ejecucion-orden-mantenimiento`);
};

export const getMaintenanceOrderTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-orden-mantenimiento`);
};

export const getExpensesTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-gasto`);
};

export const getFreightTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-flete`);
};

export const getDockTypes = async () => {
  return axios.get(`${ENUMS_PATH}/tipo-muelle`);
};

export const getTravelStates = async () => {
  return axios.get(`${ENUMS_PATH}/estado-viaje`);
};

export const getOrderStates = async () => {
  return axios.get(`${ENUMS_PATH}/estado-carga`);
};

export const getLoadStates = async () => {
  return axios.get(`${ENUMS_PATH}/estado-pedido`);
};

export const getDriverStates = async () => {
  return axios.get(`${ENUMS_PATH}/estado-chofer`);
};

export const getVehicleStates = async () => {
  return axios.get(`${ENUMS_PATH}/estado-chofer`);
};

export const getTrailerStates = async () => {
  return axios.get(`${ENUMS_PATH}/estado-remolque`);
};

export const getStatusUnsubscribe = async () => {
  return axios.get(`${ENUMS_PATH}/estado-bajaSuscripcion`);
};

export const getUbicationTypes = async () => {
  return axios.get(`https://schedule.followlsn.com/api/enumeraciones/tipo-ubicacion`);
};

export const getBayStates = async () => {
  return axios.get(`https://schedule.followlsn.com/api/enumeraciones/estado-bahia`);
};
