import React from 'react';
import { Typography, Box, TableCell, TableHead, TableSortLabel, TableRow } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';

const CargoHead = (props) => {
  const { order, orderBy, onRequestSort, module } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
  const [t] = useTranslation();

  const headCells =
    module !== 'carga'
      ? [
          {
            id: 'nroCarga',
            numeric: true,
            disablePadding: false,
            label: t('TableHeader.OrderNumber'),
            width: '20%',
          },
          {
            id: 'descripcion',
            numeric: false,
            disablePadding: false,
            label: t('TableHeader.Description'),
            width: '20%',
          },
          {
            id: 'periodoDeDescarga.finalizacion',
            numeric: false,
            disablePadding: false,
            label: t('TableHeader.Delivery-Date'),
            width: '20%',
          },
          {
            id: 'estadoCarga',
            numeric: false,
            disablePadding: false,
            label: t('TableHeader.Status'),
            width: '20%',
          },
          {
            id: 'calificar',
            numeric: true,
            disablePadding: false,
            disableSort: true,
            label: 'Calificar',
            width: '20%',
          },
        ]
      : [
          {
            id: 'nroViaje',
            numeric: true,
            disablePadding: false,
            label: t('TableHeader.TravelNumber'),
            width: '20%',
          },
          {
            id: 'descripcion',
            numeric: false,
            disablePadding: false,
            label: t('TableHeader.Description'),
            width: '20%',
          },
          // {
          //   id: 'periodoDeDescarga.finalizacion',
          //   numeric: false,
          //   disablePadding: false,
          //   label: t('TableHeader.Delivery-Date'),
          //   width: '20%',
          // },
          // {
          //   id: 'estadoCarga',
          //   numeric: false,
          //   disablePadding: false,
          //   label: t('TableHeader.Status'),
          //   width: '20%',
          // },
          {
            id: 'calificar',
            numeric: true,
            disablePadding: false,
            disableSort: true,
            label: 'Calificar',
            width: '20%',
          },
        ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align = 'left'
            // padding = {headCell.disablePadding ? 'none' : 'normal'}
            // sortDirection = {orderBy === headCell.id ? order : false}
            // width = {headCell.width || 'auto'}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled = {headCell.disableSort || filtro}
              hideSortIcon = {headCell.disableSort}
            > */}
            <Typography variant="subtitle1" fontWeight="500">
              {headCell.label}
            </Typography>
            {/* {
                orderBy === headCell.id ? (
                  <Box component = "span" sx = {visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )
                :
                <></>
              } */}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CargoHead;
