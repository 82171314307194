import { Box, Rating, Table, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableCard from '../../../../components/table-card/TableCard';
import ScoreHead from './ScoreHead';

const TableScore = ({ rows }) => {
  const [t] = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('nroCarga');
  const [filtro, setFiltro] = useState(false);
  const [score, setScore] = useState(0);

  const handleRequestSort = (event, property) => {
    const isASC = orderBy === property && order === 'asc';
    setOrder(isASC ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableCard
        title={<Typography variant="h1">{t('ModalScore.Scores')}</Typography>}
        custompadding="0px"
        customMargin="0px"
      >
        <Box sx={{ overflow: 'auto' }}>
          <TableContainer>
            <Table
              aria-label="Calificación"
              sx={{
                whiteSpace: {
                  xs: 'nowrap',
                  sm: 'unset',
                },
              }}
            >
              <ScoreHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                filtro={filtro}
              />
              {rows && rows?.cargas?.length > 0 ? (
                rows?.cargas?.map(
                  (item) =>
                    (
                      <TableRow hover key={item.id}>
                        <TableCell sx={{ borderBottom: '0' }}>
                          <Typography variant="h5" fontWeight="600">
                            {rows?.nroViaje || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: '0' }}>
                          <Typography variant="h5" fontWeight="600">
                            {item?.empresaCreadora || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: '0' }}>
                          <Typography color="textSecondary" variant="h5" fontWeight="600">
                            {item.descripcion || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: '0', zIndex: 1000 }}>
                          <Rating
                            name="simple-controlled"
                            value={item?.calificacionesDelDador?.[0]?.puntos || 0}
                            readOnly
                          />
                        </TableCell>
                      </TableRow>
                    ) || null,
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Typography variant="h4" color="textPrimary">
                      {t('Fleet.Home.Assets-travels.Without-results')}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </TableContainer>
        </Box>
      </TableCard>
    </>
  );
};

export default TableScore;
