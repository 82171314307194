import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';

import axios from 'axios';
import PropTypes from 'prop-types';
import { login as loginApi } from '../api/auth';
import { viewCompany } from '../api/company';
import { authReducer } from '../reducers/authReducer';
import { viewUser } from '../api/users';
import { getPermissionsMap } from '../api/permissions';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  authenticatedData: null,
  companyData:null,
  allPermissions: null,
  subscriptions: [],
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const getAllPermissions = async () => {
  try {
    const {data}= await getPermissionsMap(); 
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

// const getSubscriptions = async (tokenResponse) => {
//   try {
//     const { data } = await getCompany(tokenResponse.idEmpresa);
//     return data?.promocion;
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// };




// const activarMensajes = async () => {
//   const token = await getToken(messaging, {
//     vapidKey:
//       'BAYkIaXqM6Gky2G_XexXLh4kI4YK9Vcea7PNBCntsMOgxO9S9Jsp_xcv_b6c6fpTQAanGz2E210kSU6BTkdxoM8',
//   }).catch((error) => console.log('No se pudo generar el token de notificación push', error));

//   if (token) {
//     await updateTokenApp({ token }).catch((error) =>
//       console.log('tokenAppException ', error),
//     );
//   }
// };

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  subscriptions: [],
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken') || null;
        if (accessToken) setSession(accessToken);
        else throw new Error;
        const userResponse = await viewUser(accessToken);
        if (userResponse) {
          const data = await viewCompany(accessToken);
          const allPermissionsResponse = await getAllPermissions();
          const subscriptionVerification = data?.promocion;
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              authenticatedData: userResponse,
              allPermissions: allPermissionsResponse,
              subscriptions: subscriptionVerification,
              companyData:data
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              authenticatedData: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            authenticatedData: null,
          },
        });
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    let timeoutId;
    const ejecutarDespuesDeEspera = async () => {
      try {
        // await renewToken(accessToken)
        localStorage.removeItem('differenceInSeconds');
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    };
    if (accessToken && !localStorage.getItem('differenceInSeconds')) {
      const decodedToken = jwtDecode(accessToken);
      const issuedAtTime = new Date(decodedToken.iat * 1000);
      const expirationTime = new Date(decodedToken.exp * 1000);
      const differenceInMilliseconds = expirationTime.getTime() - issuedAtTime.getTime();
      localStorage.setItem('differenceInSeconds', differenceInMilliseconds);
      timeoutId = setTimeout(ejecutarDespuesDeEspera, localStorage.getItem('differenceInSeconds'));
    }

    return () => clearTimeout(timeoutId);
  }, [state]);

  const login = async (username, password) => {
    const loginResponse = await loginApi({
      username,
      password,
    });

    // activarMensajes();

    if (loginResponse.data !== 'Credenciales inválidas') setSession(loginResponse?.data);

    const userResponse = await viewUser(loginResponse?.data);

    if (userResponse) {
      const data = await viewCompany(loginResponse?.data);
      const allPermissionsResponse = await getAllPermissions();
      const subscriptionVerification = data?.promocion;

      dispatch({
        type: 'LOGIN',
        payload: {
          authenticatedData: userResponse,
          allPermissions: allPermissionsResponse,
          subscriptions: subscriptionVerification,
          companyData:data,
        },
      });
    }
    return loginResponse?.data;
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
